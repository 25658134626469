import React from "react";

const Isi = () => {
    return (
        <div className="important-info jevtana pt-1 m-t-0">
            <h4>INDICATION</h4>
            <p>
                JEVTANA (cabazitaxel) is a prescription medicine used with the steroid
                medicine prednisone to treat men with castration-resistant prostate
                cancer (prostate cancer that is resistant to medical or surgical
                treatments that lower testosterone) that has spread to other parts of
                the body, and that has worsened (progressed) after treatment with other
                medicines that included docetaxel.
            </p>
            <p>
                It is not known if JEVTANA is safe and effective in females or children.
            </p>

            <h4>IMPORTANT SAFETY INFORMATION</h4>

            <p className="jevtana-blue"><strong>What is the most important information I should know about JEVTANA (cabazitaxel)?</strong></p>

            <p><strong>JEVTANA may cause serious side effects, including:</strong></p>

            <ul>
                <li><strong>Low white blood cell counts</strong> are common during treatment with JEVTANA and can cause serious infections that may lead to death. Men who are 65 years or older may be more likely to have these problems. Your healthcare provider (HCP):</li>
                <ul>
                    <li>will do blood tests regularly to check your white blood cell counts during your treatment with JEVTANA.</li>
                    <li>may lower your dose of JEVTANA, change how often you receive it, or stop JEVTANA until your HCP decides that you have enough white blood cells.</li>
                    <li>
                        may prescribe a medicine for you called G-CSF, to help prevent complications if your white blood cell count is too low.
                    </li>
                    <li><strong>Tell your HCP right away if you have any of these symptoms of infection during treatment with JEVTANA:</strong> fever (take your temperature often during treatment with JEVTANA), cough, burning on urination, or muscle aches. Also, tell your HCP if you have any diarrhea during the time that your white blood cell count is low. Your HCP may prescribe treatment for you as needed.</li>
                </ul>
            </ul>


            <ul>
                <li>
                <strong>Severe allergic reactions</strong> can happen within a few minutes after your infusion of JEVTANA starts, especially during the first and second infusions. Your HCP should prescribe medicines before each infusion to help prevent severe allergic reactions.
                <br/>
                <strong>Tell your HCP right away if you have any of these symptoms of a severe allergic reaction during or soon after an infusion of JEVTANA:</strong> rash or itching, skin redness, feeling dizzy or faint, breathing problems, chest or throat tightness, or swelling of the face.
                </li>
            </ul>



            <ul>
                <li><strong>Severe stomach and intestine (gastrointestinal) problems.</strong></li>
                <ul>
                    <li>
                        <strong>JEVTANA can cause severe vomiting and diarrhea, which may lead to death.</strong> Severe vomiting and diarrhea with JEVTANA can lead to loss of too much body fluid (dehydration), or too much of your body salts (electrolytes). Death has happened from having severe diarrhea and losing too much body fluid or body salts with JEVTANA. <strong>You may need to go to the hospital for treatment.</strong> Your HCP will prescribe medicines to prevent or treat vomiting and diarrhea, as needed with JEVTANA. Tell your HCP if you have vomiting or diarrhea, or if your symptoms get worse or do not get better.
                    </li>
                    <li>
                        <strong>JEVTANA can cause a leak in the stomach or intestine, intestinal blockage, infection, and bleeding in the stomach or intestine, which may lead to death.</strong> Tell your HCP if you get any of these symptoms: severe stomach-area (abdomen) pain, constipation, fever, blood in your stool, or changes in the color of your stool.</li>
                </ul>
            </ul>

            <ul>
                <li>
                    <strong>Kidney failure</strong> may happen with JEVTANA, because of severe infection, loss of too much body fluid (dehydration), and other reasons, which may lead to death. Your HCP will check you for this problem and treat you if needed.
                    <br />
                    <strong>Tell your HCP right away if you develop these signs or symptoms:</strong> swelling of your face or body, decrease in the amount of urine that your body makes each day or blood in your urine.
                </li>
            </ul>

            <ul>
                <li><strong>Inflammation of the bladder and blood in the urine.</strong> Blood in the urine is common with JEVTANA, but it can also sometimes be severe. Some people who have had pelvic radiation in the past may develop inflammation of the bladder and blood in the urine that is severe enough that they need to be hospitalized for medical treatment or surgery. Your healthcare provider will check you for these problems during treatment with JEVTANA. Your healthcare provider may stop your treatment with JEVTANA for a short time, or permanently, if you develop inflammation of the bladder and bleeding that is severe.</li>
            </ul>

            <ul>
                <li>
                    <strong>Lung or breathing problems</strong> may happen with JEVTANA and may lead to death. Men who have lung disease before receiving JEVTANA may have a higher risk for developing lung or breathing problems with JEVTANA treatment. Your HCP will check you for this problem and treat you if needed.
                    <br />
                    Tell your HCP right away if you develop any new or worsening symptoms, including trouble breathing, shortness of breath, chest pain, cough or fever.
                </li>
            </ul>

            <h4>Who should not receive JEVTANA?</h4>

            <p><strong>Do not receive JEVTANA if:</strong> your white blood cell (neutrophil count) is too low, you have had a severe allergic reaction to cabazitaxel or other medicines that contain polysorbate 80 (ask your HCP if you are not sure), or you have severe liver problems.</p>

            <h4>What should I tell my HCP before receiving JEVTANA?</h4>

            <p><strong>Before receiving JEVTANA, tell your HCP if you: </strong></p>
            <ul>
                <li>are age 65 or older</li>
                <li>had allergic reactions in the past</li>
                <li>have kidney or liver problems</li>
                <li>have lung problems</li>
                <li>are pregnant or plan to become pregnant. JEVTANA can cause harm to your unborn baby and loss of pregnancy (miscarriage)</li>
                <li>are a male with a female partner who is able to become pregnant. Males should use effective birth control (contraception) during treatment with JEVTANA and for 4 months after the last dose of JEVTANA.</li>
            </ul>

            <p>JEVTANA may cause fertility problems in males. This may affect your ability to father a child. Talk to your HCP if you have concerns about fertility.</p>

            <p><strong>Tell your HCP about all the medicines you take,</strong> including prescription and over-the-counter medicines, vitamins, and herbal supplements. JEVTANA can interact with many other medicines. Do not take any new medicines without asking your HCP first. Your HCP will tell you if it is safe to take the new medicine with JEVTANA.</p>

            <h4>What are the most common side effects of JEVTANA?</h4>

            <p><strong>The most common side effects of JEVTANA include:</strong></p>

            <ul>
                <li>low red blood cell count (anemia), which is common with JEVTANA, but can sometimes also be serious. Your HCP will regularly check your red blood cell count. Symptoms of anemia include shortness of breath and tiredness.</li>
                <li>low blood platelet count, which is common with JEVTANA, but can sometimes also be serious. Tell your HCP if you have any unusual bruising or bleeding.</li>
            </ul>

            <div className="split-isi-list">
                <ul className="isi--half">
                    <li>diarrhea</li>
                    <li>tiredness</li>
                    <li>nausea</li>
                    <li>vomiting</li>
                    <li>constipation</li>
                </ul>
                <ul className="isi--half">
                    <li>back pain</li>
                    <li>decreased appetite</li>
                    <li>stomach (abdominal) pain</li>
                    <li>weakness</li>
                </ul>
            </div>

            <p><strong>Tell your HCP if you have any side effect that bothers you or that does not go away.</strong> These are not all the possible side effects of JEVTANA. For more information, ask your HCP or pharmacist.</p>

            <p>Call your doctor for medical advice about side effects. You may report side effects to the FDA at 1-800-FDA-1088.</p>

            <p><strong>Please see full <a href="https://products.sanofi.us/jevtana/jevtana.html" target="_blank">Prescribing Information</a> / <a href="https://products.sanofi.us/jevtana/jevtana.html#section-21" target="_blank">Patient Information</a>, including Serious Side Effects.</strong></p>

        </div>
    );
};

export default Isi;
